<template>
  <div>
    <v-row>
      <v-col cols="9" class="imageLogin"
        ><img
          class="image"
          src="../assets/escape_login_bg.png"
          alt="bg image"
        />
        <div class="appTitle">
          <v-row>
            <v-col>
              <v-row>Escape Velocity</v-row>
              <v-row class="appTitleActual"> Digital Solutions </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="3" class="right">
        <v-row class="logoClass">
          <v-col cols="2"></v-col>
          <v-col cols="6">
            <img
              src="../assets/escape_logo.png"
              width="200"
              alt="Escape Velocity"
            />
          </v-col>
        </v-row>
        <!-- <form>
          <v-row class="eachForm"> </v-row>
          <v-row class="eachForm">
            <v-text-field
              class="toBeFilled"
              v-model="email"
              required
              dense
              outlined
              label="email"
            ></v-text-field>
          </v-row>
          <v-row class="eachForm">
            <v-text-field
              class="toBeFilled"
              v-model="password"
              :type="'password'"
              required
              dense
              outlined
              label="Password"
            >
            </v-text-field>
          </v-row>
          <v-row class="eachForm">
            <v-btn
              class="toBeFilled"
              id="loginButton"
              color="#EE4036"
              @click="login()"
              @submit="login()"
              >Login</v-btn
            >
          </v-row>
        </form> -->
        <form
          @submit="login()"
          class="loginForm"
        >
          <p >
            <!-- <label for="email">Email</label> -->
            <input class="loginInput" id="email" placeholder="Email" v-model="email" type="email" name="email" />
          </p>

          <p >
            <!-- <label for="password">Password</label> -->
            <input class="loginInput" id="password" placeholder="Password" v-model="password" type="password" name="password" min="0" />
          </p>

          <p >
            <input class="loginButton" type="submit" @click.stop.prevent="login()" value="Login" />
          </p>
        </form>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import constants from "../constants";
import jwt from "jwt-decode";
const axios = require("axios").default;

export default {
  data: () => ({
    email: "",
    password: "",
    items: [],
    select: "",
  }),

  methods: {
    reset() {
      //this.$refs.form.reset();
      this.email = "";
      this.password = "";
      this.select = "";
    },
    login: async function () {
      console.log("selected:", this.select);
      const auth = {
        email: this.email,
        password: this.password,
      };
      this.success = false;
      this.error = null;
      console.log("the auth is:", auth);

      try {
        var config = {
          method: "post",
          url: `${constants.backend_url}/login`,
          headers: {
            "Content-Type": "application/json",
            email: auth.email,
            password: auth.password,
          },
        };
        const res = await axios(config);
        if (res.data.token) {
          sessionStorage.setItem("jwt", res.data.token);
          let userRole = jwt(res.data.token).role;
          if (userRole === "admin") {
            this.$router.push("/listRateCards");
          } else {
            this.$router.push("/listAgencies");
          }
        } else {
          alert("login failed");
        }
      } catch (err) {
        alert("login failed", err);
        this.error = err.message;
      }
    },
  },
};
</script>

<style>
.span1 {
  color: #0000cd;
}
.span2,
.org,
.email,
.pwd {
  color: red;
}
.create {
  width: 15pm;
}
.spacer5 {
  height: 5px;
}
.label {
  color: red;
  font-weight: bold;
  font-size: 17px;
}

.tdLabel {
  text-align: right;
  padding-right: 50px;
}

.tdContent {
  align-content: center;
}

.tdbutton {
  text-align: center;
  padding: 5px;
}
.imageLogin {
  margin: 0px;
  padding: 0px;
  height: 100vh;
  overflow: hidden;
  position: relative;
  text-align: center;
  color: white;
}
.image {
  height: 100vh;
  width: 100%;
}
.toBeFilled {
  padding-left: 10% !important;
  padding-right: 10% !important;
  margin: auto;
  width: 50%;
}
.appTitle {
  position: absolute;
  bottom: 8px;
  left: 60px;
}
.appTitleActual {
  font-size: 370%;
}
.logoClass {
  padding-top: 40%;
}
.eachForm {
  padding-top: 5%;
}

.right {
  background: #a2a2a2;
}
.loginForm{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 14vh;
}
.loginButton{
  background-color: #EE4036;
  padding:1px 90px 1px 90px;
  font-size: 25px;
  font-weight: 600;
  border-radius: 6px;

  color: white;
}
.loginInput{
  border: 1px solid black;
  padding: 10px 40px 10px 40px ;
  border-radius: 5px;
}
</style>